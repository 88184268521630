.button {
	margin-top: 25px;
	margin-bottom: 25px;
	box-sizing: border-box;
	pointer-events: auto;
	cursor: pointer;
	border: none;
	width: 210px;
	height: 50px;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.button--pandora {
	background: #000;
	font-weight: 700;
	padding: 0;
	border-radius: 5px;
}

.button--pandora span {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	background: #ffffff;
	border: 1px solid #000;
	border-radius: 5px;
	transform: translate3d(-4px, -4px, 0);
	transition: transform 0.2s cubic-bezier(0.7, 0, 0.2, 1);

	font-family: "Roboto", sans-serif;
	font-weight: 600;
	font-size: 19px;
}

.button--pandora:active span {
	transform: translate3d(-8px, -8px, 0);
}

