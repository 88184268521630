* {
	box-sizing: border-box;
}
body {
	overflow-x: hidden;
	z-index: 0;
	width: 100%;
	height: 100vh;
}
.back {
	display: flex;
	position: fixed;
	top: 15px;
	left: 25px;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-size: 30px;
	color: white;
}
.back > p {
	margin-left: 6px;
}
.back:hover {
	cursor: pointer;
}
.cards {
	padding-top: 35px;
	position: relative;
	z-index: 1;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: space-around;
	align-items: center;
	overflow-y: hidden;
	background-color: #011e30a9;
}
.cards::before {
	padding-top: 35px;
	content: " ";
	position: absolute;
	z-index: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0.4;
	background-image: url("../../Assests/background.jpg");
	background-repeat: no-repeat;
	background-size: auto;
}
.background::before {
	content: " ";
	position: absolute;
	z-index: 0;
	width: 100vw;
	height: 100%;
	opacity: 0.4;
	background-image: url("../../Assests/background.jpg");
	background-repeat: repeat;
	background-size: auto;
}
.cards > ion-icon {
	text-align: center;
	font-size: 60px;
	color: white;
}
/* Estilos para o Card */
.card {
	display: flex;
	flex-direction: column;
	height: 550px;
	width: 400px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 8px 7px 6px 1px #000000;
}
.avancar {
	margin-left: 40px;
}
.retornar {
	margin-right: 40px;
}
.topo-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 34%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
/* Topo Carda */
.topo-card > span {
	width: 90%;
	margin-left: 25px;
}
.titulo {
	font-family: "Roboto", sans-serif;
	margin-bottom: 20px;
	font-size: 40px;
	font-weight: 600;
}
.paragrafo {
	font-family: "Roboto", sans-serif;
	font-size: 20px;
	font-weight: 300;
}

/* Meio Card */
.meio-card {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	height: 33%;
}
.alternativas {
	display: flex;
	font-family: "Roboto", sans-serif;
	justify-content: space-around;
	font-size: 20px;
	font-weight: 600;
}
.alternativa {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 22px;
	font-weight: 600;
}
.selecionado {
	fill: #011e30;
}

/* Base Card */
.base-card {
	display: flex;
	justify-content: center;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	height: 33%;
}
.base-card > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	font-family: "Roboto", sans-serif;
	font-size: 25px;
	font-weight: 600;
}
.sua-resposta {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	margin-top: 20px;
	border: 2px solid black;
	border-radius: 40px;
	background-color: rgba(196, 191, 191, 0.692);
}
.sua-resposta > input {
	border: none;
	width: 55px;
	font-family: "Roboto", sans-serif;
	font-weight: 600;
	font-size: 30px;
	text-align: center;
	background-color: rgba(196, 191, 191, 0);
}
svg,
ion-icon {
	cursor: pointer;
}
.teste {
	width: 30px;
	height: 30px;
	background-color: aliceblue;
}
/* Animaçao */
.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-animation-delay: 1.2s;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
/* Tirar barra lateral do input NUMBER */
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
input[type="number"] {
	-moz-appearance: textfield;
	appearance: textfield;
}
