button {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  outline:0;
}
.arrow {
  cursor: pointer;
  
}
.left:hover polyline,
.left:focus polyline {
  stroke-width: 3;
}

.left:active polyline {
  stroke-width: 6;
  transition: all 100ms ease-in-out;
}

.right:hover polyline,
.right:focus polyline {
  stroke-width: 3;
}

.right:active polyline {
  stroke-width: 6;
  transition: all 100ms ease-in-out;
}

polyline {
  transition: all 250ms ease-in-out;
}
/*# sourceMappingURL=efeitoSeta.css.map */